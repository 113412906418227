import React from "react";
import storylinesLogo from "./images/storylines.png";

export default function ProjectSlideStorylines() {
  return (
    <div style={{ display: "grid", justifyItems: "center", padding: "24px", textAlign: "center", height: "80%" }}>
      <h3>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href="https://storylines.mobi"
          target="_blank"
          rel="noreferrer"
        >
          Storylines
        </a>
      </h3>
      <a
        onClick={() =>
          window.gtag("event", "click", {
            event_category: "storylines",
            event_label: "open storylines",
          })
        }
        style={{ textDecoration: "none", color: "black" }}
        href="https://storylines.mobi"
        target="_blank"
        rel="noreferrer"
      >
        <img src={storylinesLogo} style={{ height: "150px" }} alt="loyaltyBru logo" />
      </a>
      <div>
        <p>Stories you and your friends can build on</p>
        <span
          style={{ color: "red", fontSize: "smaller", opacity: "0.6", position: "absolute", bottom: 10, right: 20 }}
        >
          Unmaintained project
        </span>
      </div>
    </div>
  );
}
