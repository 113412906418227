import React from "react";

function ContactUs() {
  return (
    <a
      onClick={() =>
        window.gtag("event", "click", {
          event_category: "contact us",
          event_label: "open contact us",
        })
      }
      style={{ textDecoration: "none", color: "black" }}
      href="mailto:developer@sunbirddigital.com"
      target="_blank"
      rel="noreferrer"
    >
      Contact Us
    </a>
  );
}

export default ContactUs;
