import React from "react";
import flowlasticLogo from "./images/FlowlasticLogo.png";

export default function ProjectSlideFlowlastic() {
  return (
    <div style={{ display: "grid", justifyItems: "center", padding: "24px", textAlign: "center", height: "80%" }}>
      <h3>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href="https://flowlastic.com"
          target="_blank"
          rel="noreferrer"
        >
          Flowlastic
        </a>
      </h3>
      <a
        onClick={() =>
          window.gtag("event", "click", {
            event_category: "flowlastic",
            event_label: "open flowlastic",
          })
        }
        style={{ textDecoration: "none", color: "black" }}
        href="https://flowlastic.com"
        target="_blank"
        rel="noreferrer"
      >
        <img src={flowlasticLogo} style={{ height: "100px" }} alt="loyaltyBru logo" />
      </a>
      <p>Community built flows explaining how to do just about anything.</p>
    </div>
  );
}
