import "./App.css";
import logo from "./images/Sunbird Digital.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import React, { useRef, useState } from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";
import HomeSlide from "./HomeSlide";
import AboutSlide from "./AboutSlide";
import ProjectSlideLoyaltyBru from "./ProjectSlideLoyaltyBru";
import ProjectSlideFlowlastic from "./ProjectSlideFlowlastic";
import ProjectSlideWhereDidWeGo from "./ProjectSlideWhereDidWeGo";
import ContactUs from "./ContactUs";
import ProjectSlideStorylines from "./ProjectSlideStorylines";

function App() {
  return (
    <div style={{ display: "grid", alignItems: "start" }}>
      <div style={{ height: "15vh" }}></div>
      <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper">
        <SwiperSlide>
          <HomeSlide />
        </SwiperSlide>
        <SwiperSlide>
          <AboutSlide />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectSlideFlowlastic />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectSlideWhereDidWeGo />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectSlideLoyaltyBru />
        </SwiperSlide>
        <SwiperSlide>
          <ProjectSlideStorylines />
        </SwiperSlide>
        <SwiperSlide>
          <ContactUs />
        </SwiperSlide>
      </Swiper>
      <div style={{ width: "100%", marginTop: "100px", display: "grid", justifyItems: "center", zIndex: "3000" }}>
        <a
          style={{ textDecoration: "none", color: "black", fontWeight: "bold", cursor: "pointer" }}
          href="mailto:developer@sunbirddigital.com"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </a>
      </div>
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}

export default App;
