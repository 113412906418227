import React from "react";
import wheredidwegoLogo from "./images/WhereDidWeGoMain.png";

export default function ProjectSlideWhereDidWeGo() {
  return (
    <div style={{ display: "grid", justifyItems: "center", padding: "24px", textAlign: "center", height: "80%" }}>
      <h3>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href="https://wheredidwego.com"
          target="_blank"
          rel="noreferrer"
        >
          Where Did We Go
        </a>
      </h3>
      <a
        onClick={() =>
          window.gtag("event", "click", {
            event_category: "wheredidwego",
            event_label: "open wheredidwego",
          })
        }
        style={{ textDecoration: "none", color: "black" }}
        href="https://wheredidwego.com"
        target="_blank"
        rel="noreferrer"
      >
        <img src={wheredidwegoLogo} style={{ height: "100px" }} alt="loyaltyBru logo" />
      </a>
      <p>A visualisation of South African emigration over the years</p>
    </div>
  );
}
