import React from "react";
import loyaltyBruLogo from "./images/LoyaltyBruLogo.png";
import squares from "./images/squareSmall.jpg";

export default function ProjectSlideLoyaltyBru() {
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "center",
        padding: "24px",
        textAlign: "center",
        height: "80%",
      }}
    >
      <h3>
        <a
          onClick={() =>
            window.gtag("event", "click", {
              event_category: "loyaltybru",
              event_label: "open loyaltybru",
            })
          }
          style={{ textDecoration: "none", color: "black" }}
          href="https://loyaltybru.com"
          target="_blank"
          rel="noreferrer"
        >
          LoyaltyBru
        </a>
      </h3>
      <a
        style={{ textDecoration: "none", color: "black" }}
        href="https://loyaltybru.com"
        target="_blank"
        rel="noreferrer"
      >
        <img src={loyaltyBruLogo} style={{ height: "100px" }} alt="loyaltyBru logo" />
      </a>
      <p>A simple loyalty system for anyone</p>
    </div>
  );
}
