import React from "react";
import codingBlocks from "./images/codingBlocks.png";

function AboutSlide() {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto auto auto",
        justifyItems: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <p style={{ padding: "0px 24px", margin: 0, textAlign: "center" }}>
        <strong>Sunbird Digital</strong>
        <br />
        <br />
        Specializing in ReactJS and AWS based Web apps.
        <br />
        <br />
      </p>
      <img src={codingBlocks} alt="logo" style={{ maxHeight: "150px" }} />
      <i style={{ fontSize: "smaller" }}>"Coding our piece of the world wide web"</i>
    </div>
  );
}

export default AboutSlide;
